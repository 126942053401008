<template>
  <div>
    <el-breadcrumb separator="/">
      <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>质控指标</el-breadcrumb-item>
    </el-breadcrumb>
    <div class="warp">

      <el-table :data="list" style="width: 80%" v-loading="isLoading">
        <el-table-column prop="Name" label="名称">
        </el-table-column>
        <el-table-column fixed="right" label="操作" width="150">
          <template slot-scope="scope">
            <el-button @click="handleClick(scope.row)" type="text" size="small">查看</el-button>
          </template>
        </el-table-column>
      </el-table>

    </div>
  </div>
</template>

<script>
import { getQCVideoMains } from '@/api'

export default {
  name: "ZCZB",
  data() {
    return {
      list: [],
      isLoading: false,
    }
  },
  methods: {
    async getQCVideoMainsAsync() {
      this.isLoading = true
      const r = await getQCVideoMains()
      this.isLoading = false
      if (r.code == 1) {
        this.list = r.data
      } else {
        const msg = r.error.Message;
        this.$message.error(msg);
      }
    },
    handleClick(row) {
      this.$router.push({
        path:`/ZCZBDetail/${row.Id}/${row.Name}`
      })
    }
  },
  created() {
    this.getQCVideoMainsAsync()
  }

}
</script>

<style scoped>
.warp {
  margin: 40px;
}

</style>